import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Styleguide = () => (
  <Layout>
    <SEO title="Home" />

    <section className="max outerx4 guttersx4">
      <h1>Heading</h1>
      <h2>Subheading</h2>
      <p>Purple are the leading global lifestyle communications agency, combining our expertise with a range of services that are unique within the market.</p>
      <h3 className="upcase">Detail</h3>
    </section>

  </Layout>
)

export default Styleguide
